<template>
  <div class="p-1">
    <div class="flex justify-between flex-wrap mb-2 items-center">
      <h3 class="text-2xl">Entregas finales</h3>
      <a-button :to="{ name: 'create_final_delivery' }" v-if="user.isAdmin" class="w-full md:w-auto">
        Crear entrega final
      </a-button>
    </div>

    <a-table
      :columns="columns"
      :source="pagination.data"
      :loading="loading"
      :pagination="pagination"
      @paginate="search({ page: $event })"
      toolbar-column-filter
      @delayed-filter:date="search({ date: $event, page: 1 })"
      @delayed-filter:course_section_course_title="search({ course_name: $event, page: 1 })"
      @delayed-filter:course_section_section_letter_code="search({ section_letter_code: $event.toUpperCase(), page: 1 })"
      @toolbar(refresh)="search({ page: 1 })">

      <template #filter(date)>
        <a-input class="mb-4" id="date" type="date" v-model="query.date"/>
      </template>

      <template #filter(specialization.title)>
        <v-select
            @clear="search({ page: 1 })"
            :clearable="true"
            :options="mappedSpecialities"
            :reduce="$0 => $0.value"
            placeholder="Especialidad"
            class="w-48"
            v-model="query.specialization"/>
      </template>

      <template #toolbar>
        <a-period-accessor v-model="query.period" />
      </template>

      <template #td(actions)="{ item }">
        <a-dropdown>
          <template #menu>
            <a-dropdown-item :to="{ name: 'details_final_delivery', params: { delivery_id: item.id } }">
              Detalles
            </a-dropdown-item>
            <a-dropdown-item v-if="user.isAdmin" :to="{ name: 'update_final_delivery', params: { delivery_id: item.id } }">
              Actualizar
            </a-dropdown-item>
            <a-dropdown-item @click="deleteFinalDelivery(item.id)" v-if="item.date && checkDateForDeletion(item.date) && user.isAdmin">
              Eliminar
            </a-dropdown-item>
          </template>
        </a-dropdown>
      </template>
      <template #td(time)="{ item }">
        <p>
          {{ mappedTime(item) }}
        </p>
      </template>
<!--      <template #td(actions2)="{ item }">-->
<!--        <a-dropdown>-->
<!--          <template #menu>-->

<!--          </template>-->
<!--        </a-dropdown>-->
<!--      </template>-->
    </a-table>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapGetters, mapActions } from 'vuex';
export default {
  data: () => ({
    query: {
      limit: 10,
      search: '',
      period: null,
      with: `period,specialization,courseSection.course,courseSection.section,courseSection.semester,classroom,courseSection.teacher,juries`,
      specialization: '',
      sort_by: 'date',
      date: '',
    },
    timeout: null
  }),
  computed: {
    ...mapState({
      user: ({ session }) => session.user,
      pagination: ({ finalDeliveries }) => finalDeliveries.pagination,
      spec: state => state.specializations.pagination,
      loading: ({ finalDeliveries }) => finalDeliveries.loading
    }),
    ...mapGetters(['lang']),
    mappedSpecialities() {
      return this.spec.data.map($0 => ({ label: $0.title, value: $0.id }))
    },
    columns() {
      return [
        { title: 'Fecha', key: 'date', display: true, mutate: ({ key }) => key ? moment.utc(key).format('DD-MM-YYYY') : '- Sin fecha -', filter: true, preventLocalFilter: true},
        { title: 'Hora', slot: 'time', display: true },
        // { title: 'Período', key: 'period', mutate: ({ key }) => `${key.year}-${key.number}`, display: true },
        { title: 'Especialidad', key: 'specialization.title', filter: true, preventLocalFilter: true, display: true },
        { title: 'Materia', key: 'course_section.course.title', filter: true, preventLocalFilter: true, display: true },
        { title: 'Sección', key: 'course_section.section.letter_code', filter: true, preventLocalFilter: true, display: true },
        { title: 'Semestre', key: 'course_section.semester.number', display: true },
        { title: 'Aula', key: 'classroom.code', display: true },
        { title: 'Profesor', key: 'course_section.teacher', mutate: ({ key }) => `${key.first_name} ${key.last_name}`, display: true },
        { title: 'Jurado', key: 'juries', mutate: ({ key }) => key.map($0 => `${$0.first_name} ${$0.last_name}`).join(', '), display: true },
        { title: 'Acciones', slot: 'actions', alignment: 'right', columnFilterable: false, display: true }
        // { title: 'Acciones', slot: 'actions2', alignment: 'right', columnFilterable: false, display: true },
      ].filter($0 => $0.display)
    },
  },
  methods: {
    ...mapActions({
      fetchSpecializations: 'specializations/fetchSpecializations',
      fetchFinalDeliveries: 'finalDeliveries/fetchAll',
      deleteFinalDelivery: 'finalDeliveries/delete'
    }),
    moment: () => moment(),
    checkDateForDeletion(date) {
      return moment(moment(), "YYYY-MM-DD").isBefore(moment(date, "YYYY-MM-DD"))
    },
    search(query) {
      return this.fetchFinalDeliveries({ ...this.query, ...query })
    },
    mappedTime (item) {
      const time = moment.utc(item.date).format('hh:mm A')
      const endTime = moment.utc(item.delivery_until_at).format('hh:mm A')

      if (item.date && item.delivery_until_at) {
        return time + ' / ' + endTime
      } else {
        return '- Sin hora-'
      }
    },
    fetchSpec () {
      this.fetchSpecializations()
        .finally(() => {
          this.specializations = this.spec.data.map($0 => ({ label: $0.title, value: $0.id }))
        })
    }
  },
  watch: {
    'query.period'(val) {
      if (val) {
        this.search(this.query)
      }
    },
    'query.specialization'() {
      this.fetchFinalDeliveries({ ...this.query, page: 1 })
    },
    'query.date'() {
      this.fetchFinalDeliveries({ ...this.query, page: 1 })
    }
  },
  mounted() {
    this.fetchSpec()
    Promise.all([
      this.$repository.periods.enabled()
    ])
    .then(([{ data: period }]) => {
      this.query.period = period.id
    })
  }
}
</script>
